import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
	const [videos, setVideos] = useState([]);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [categoryId, setCategoryId] = useState(93);

	const categories = [
		{ id: 93, name: 'All' },
		{ id: 96, name: 'Sport' },
		{ id: 95, name: 'Drama' },
		{ id: 94, name: 'Music' },
		{ id: 97, name: 'Lectures' },
		{ id: 83, name: 'Archive Footage' },
	];

	useEffect(() => {
		const fetchData = async () => {
			const url = `https://estream.sherborne.org/Api/6.04/Search/?pageindex=0&pagesize=20&search=${
				searchTerm || '*'
			}&catid=${categoryId}&APIKey=fwol0aefev3egbnalfxgppultpdhygupufrxlle8xm`;
			const response = await fetch(url);
			const data = await response.json();
			setVideos(data);
		};
		fetchData();
	}, [searchTerm, categoryId]);

	const renderVideoList = () => {
		return (
			<div className="video-list">
				{videos.map((video) => (
					<div
						className="video"
						key={video.ID}
						onClick={() => setSelectedVideo(video)}
					>
						<div className="video-thumbnail">
							<img src={video.ThumbnailURL} alt={video.Title} />
						</div>
						<div className="video-info">
							<h3 className="video-title">{video.Title}</h3>
							<p className="video-description">{video.Description}</p>
						</div>
					</div>
				))}
			</div>
		);
	};

	const renderSelectedVideo = () => {
		if (!selectedVideo) {
			return null;
		}
		return (
			<div className="selected-video">
				<div className="embed-container">
					<div
						dangerouslySetInnerHTML={{ __html: selectedVideo.EmbedCode }}
					></div>
				</div>
				<button
					className="return-button"
					onClick={() => setSelectedVideo(null)}
				>
					Return to search results
				</button>
			</div>
		);
	};

	return (
		<div className="App">
			<div className="header">
				<h1>Sherborne TV</h1>
				<div className="search">
					<input
						type="text"
						placeholder="Search videos..."
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
					<select
						value={categoryId}
						onChange={(e) => setCategoryId(parseInt(e.target.value))}
					>
						{categories.map((category) => (
							<option value={category.id} key={category.id}>
								{category.name}
							</option>
						))}
					</select>
				</div>
			</div>
			{renderSelectedVideo() || renderVideoList()}
		</div>
	);
}

export default App;
